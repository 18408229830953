/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";

const Index = () => (
  <div className="container d-flex flex-grow-1" style={{ marginTop: `-5rem` }}>
    <div className="row flex-column justify-content-center align-items-center">
      <Helmet title={"Matt Williams | Software Developer"} />
      <div className="col-12 col-md-11 col-lg-8 d-flex flex-column justify-content-center">
        <div className="lead mt-3 mb-4">
          <span>
            Hi, I'm Matt{" "}
            <span role="img" aria-label="wave">
              👋
            </span>
          </span>
        </div>
        <h1 className="display-1">I'm a full-stack web developer</h1>
        <div className="lead mt-3 mb-4">
          <span>I build web applications</span>{" "}
          <div className="align-middle d-inline-block">
            <Link to="/about">
              <i className="fas fa-arrow-alt-circle-right fa-lg" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Index;
